import getConfig from 'next/config';
import cookie from 'cookie';

const { publicRuntimeConfig } = getConfig() ?? {};
const {
    NEXT_PUBLIC_MYFLOOD_POLICY_PORTAL_UI_HOST,
    NEXT_PUBLIC_MYFLOOD_REFRESH_TOKEN_COOKIE_DOMAIN,
    NEXT_PUBLIC_NFS_POLICY_PORTAL_UI_HOST,
    NEXT_PUBLIC_POLICY_ORIGIN_COOKIE
} = publicRuntimeConfig ?? {};

/**
 * Returns the correct Policy Portal UI (PPUI) host [AX-1887]
 *
 * @param {*} req
 */
const getPPUIHost = req => {
    if (req) {
        const policyOriginCookie = cookie.parse(req.headers.cookie ?? '')[
            NEXT_PUBLIC_POLICY_ORIGIN_COOKIE
        ];

        if (policyOriginCookie) {
            return policyOriginCookie;
        }

        const isMyFlood = Array.isArray(
            req.headers.host.match(
                NEXT_PUBLIC_MYFLOOD_REFRESH_TOKEN_COOKIE_DOMAIN
            )
        );

        return isMyFlood
            ? NEXT_PUBLIC_MYFLOOD_POLICY_PORTAL_UI_HOST
            : NEXT_PUBLIC_NFS_POLICY_PORTAL_UI_HOST;
    }

    if (typeof document !== 'undefined') {
        const policyOriginCookie = cookie.parse(document.cookie)[
            NEXT_PUBLIC_POLICY_ORIGIN_COOKIE
        ];

        return policyOriginCookie ?? NEXT_PUBLIC_NFS_POLICY_PORTAL_UI_HOST;
    }

    return NEXT_PUBLIC_NFS_POLICY_PORTAL_UI_HOST;
};

export default getPPUIHost;
