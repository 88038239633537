const featureSets = {
    'phase-1-linking': {
        redirects: [
            {
                url: '/login'
            },
            {
                url: '/quote/create'
            },
            {
                url: '/quote/edit'
            },
            {
                url: '/dashboard'
            },
            {
                url: '/search'
            },
            {
                url: '/endorsement'
            },
            {
                url: '/application'
            },
            {
                url: '/policy'
            }
        ]
    },
    'phase-2-linking': {
        redirects: [
            {
                url: '/login'
            },
            {
                url: '/quote/create',
                override: true
            },
            {
                url: '/quote/edit',
                override: true
            },
            {
                url: '/dashboard'
            },
            {
                url: '/search'
            },
            {
                url: '/endorsement'
            },
            {
                url: '/application'
            },
            {
                url: '/policy'
            }
        ]
    },
    'phase-3-linking': {
        redirects: [
            {
                url: '/login'
            },
            {
                url: '/quote/create'
            },
            {
                url: '/quote/edit'
            },
            {
                url: '/dashboard',
                override: true
            },
            {
                url: '/policy',
                override: true
            },
            {
                url: '/search'
            },
            {
                url: '/endorsement'
            },
            {
                url: '/application'
            },
            {
                url: '/policy'
            }
        ]
    }
};

/**
 *
 * @param {String[]} featureFlags
 */
const getRedirects = featureFlags => {
    if (!featureFlags) {
        return [];
    }

    let flags = featureFlags;
    if (typeof featureFlags === 'string') {
        flags = featureFlags.split(',');
    }

    const allRedirects = [];
    flags.forEach(flag => {
        if (featureSets[flag]) {
            allRedirects.push(...featureSets[flag].redirects);
        }
    });

    const overrides = allRedirects.reduce((acc, { url, override }) => {
        if (!override) {
            return acc;
        }

        return [...acc, url];
    }, []);

    // remove overrides
    const filteredRedirects = allRedirects.filter(
        ({ url }) => !overrides.includes(url)
    );

    return filteredRedirects;
};

export default getRedirects;
