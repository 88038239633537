import range from 'lodash/range';

export const RATE_TYPE = {
    NEWLY_MAPPED: 'Newly Mapped',
    PRP: 'Preferred Risk Policy',
    STANDARD: 'Standard',
    RISK_RATE_2: 'Risk Rating 2.0',
    PROVISIONAL_RATE: 'Provisional Rate'
};

export const RATE_TYPES = {
    PREFERRED_RISK: 'PRP',
    NEWLY_MAPPED: 'NEWLY_MAPPED',
    STANDARD: 'STANDARD'
};

export const POLICY_TYPE = {
    PREFERRED_RISK: 'Preferred Risk Policy',
    NEWLY_MAPPED: 'Newly Mapped',
    STANDARD: 'Standard',
    RISK_RATE_2: 'Risk Rating 2.0',
    PROVISIONAL_RATE: 'Provisional Rate'
};

export const POLICY_TYPES = {
    PREFERRED_RISK: 'PREFERRED_RISK',
    NEWLY_MAPPED: 'NEWLY_MAPPED',
    STANDARD: 'STANDARD'
};

export const OCCUPANCY_TYPES = {
    SINGLE_FAMILY_RESIDENTIAL: 'SINGLE_FAMILY_RESIDENTIAL',
    TWO_TO_FOUR_FAMILY_RESIDENTIAL: 'TWO_TO_FOUR_FAMILY_RESIDENTIAL',
    OTHER_RESIDENTIAL: 'OTHER_RESIDENTIAL',
    BUSINESS_NON_RESIDENTIAL: 'BUSINESS_NON_RESIDENTIAL',
    OTHER_NON_RESIDENTIAL: 'OTHER_NON_RESIDENTIAL'
};

export const COVERAGE_TYPES = {
    BUILDING_AND_CONTENTS: 'BUILDING_AND_CONTENTS',
    CONTENTS: 'CONTENTS',
    BUILDING: 'BUILDING'
};

export const DATE_FORMAT = 'MM/dd/yyyy';

export const FORM_OF_OWNERSHIP = {
    CONDOMINIUM: 'condominium'
};

export const ATTACHED_GARAGE = {
    ATTACHED: 'attached'
};

export const INSURED_TYPE = {
    PERSON: 'PERSON',
    ORGANIZATION: 'ORGANIZATION'
};

export const INSURED_TYPES = {
    PERSON: 'Insured Name',
    ORGANIZATION: 'Business Name'
};

export const SEARCH_SCOPE = {
    ALL: 'EVERYWHERE',
    NAME: 'NAME',
    POLICY: 'POLICY',
    ADDRESS: 'ADDRESS'
};

export const APPLICATION_STATUS = {
    APPLICATION: 'APPLICATION',
    FUTURE_EFFECTIVE: 'FUTURE_EFFECTIVE',
    PAID: 'PAID',
    PENDING_ISSUE: 'PENDING_ISSUE',
    QUOTE: 'QUOTE',
    WAITING_FOR_PAYMENT: 'WAITING_FOR_PAYMENT'
};

export const getFoundationType = application => {
    if (application?.foundation?.splitLevelBasement) {
        return 'SPLIT_LEVEL_BASEMENT';
    }
    if (application?.foundation?.elevatedWithEnclosure) {
        return 'ELEVATED_WITH_ENCLOSURE';
    }
    if (application?.foundation?.walkoutBasement) {
        return 'WALKOUT_BASEMENT';
    }
    if (application?.foundation?.splitLevelSlabOnGrade) {
        return 'SPLIT_LEVEL_SLAB_ON_GRADE';
    }
    if (application?.foundation?.elevatedWithoutEnclosure) {
        return 'ELEVATED_WITHOUT_ENCLOSURE';
    }
    if (application?.foundation?.subgradeCrawlspace) {
        return 'SUBGRADE_CRAWLSPACE';
    }
    if (application?.foundation?.slabOnGrade) {
        return 'SLAB_ON_GRADE';
    }
    if (application?.foundation?.aboveGroundCrawlspace) {
        return 'ABOVE_GROUND_CRAWLSPACE';
    }
    if (application?.foundation?.basement) {
        return 'BASEMENT';
    }
    return '';
};

export const FOUNDATION_TYPES = {
    BASEMENT: 'Basement',
    ABOVE_GROUND_CRAWLSPACE: 'Above ground crawlspace',
    CRAWLSPACE: 'Above ground crawlspace',
    ELEVATED_WITH_ENCLOSURE: 'Elevated with enclosure',
    ELEVATED_WITHOUT_ENCLOSURE: 'Elevated without enclosure',
    SPLIT_LEVEL_BASEMENT: 'Split level basement',
    SUBGRADE_CRAWLSPACE: 'Subgrade crawlspace',
    WALKOUT_BASEMENT: 'Walkout basement',
    SLAB_ON_GRADE: 'Slab on grade',
    SPLIT_LEVEL_ON_GRADE: 'Split level slab on grade'
};

export const FOUNDATION_TYPE_OPTIONS = {
    BASEMENT: 'BASEMENT',
    CRAWLSPACE: 'CRAWLSPACE',
    ELEVATED_WITHOUT_ENCLOSURE: 'ELEVATED_WITHOUT_ENCLOSURE',
    ELEVATED_WITH_ENCLOSURE: 'ELEVATED_WITH_ENCLOSURE',
    SUBGRADE_CRAWLSPACE: 'SUBGRADE_CRAWLSPACE',
    WALKOUT_BASEMENT: 'WALKOUT_BASEMENT',
    SLAB_ON_GRADE: 'SLAB_ON_GRADE',
    SPLIT_LEVEL_ON_GRADE: 'SPLIT_LEVEL_ON_GRADE',
    SPLIT_LEVEL_BASEMENT: 'SPLIT_LEVEL_BASEMENT',
    SPLIT_LEVEL: 'SPLIT_LEVEL',
    MANUFACTURED_HOME: 'MANUFACTURED_HOME'
};

export const ELEVATION_FOUNDATION_TYPE = {
    PIERS_POSTS_PILES_OR_COLUMN: 'Piers, Posts, or Piles',
    CONCRETE_SHEAR_WALLS: 'Concrete Shear Walls',
    MASONRY_CONCRETE_PIERS_OR_COLUMNS: 'Masonry/Concrete Piers or Columns',
    SOLID_WALLS: 'Solid Foundation Walls',
    WOOD_SHEAR_WALLS: 'Wood Shear Walls'
};

export const ELEVATION_FOUNDATION_TYPE_WO_ENCLOSURE = {
    PIERS_POSTS_PILES_OR_COLUMN: 'Piers, Posts, or Piles',
    MASONRY_CONCRETE_PIERS_OR_COLUMNS: 'Masonry/Concrete Piers or Columns',
    WOOD_SHEAR_WALLS: 'Wood Shear Walls'
};

export const WALL_MATERIAL = {
    LIGHT_WOOD_LATTICE: 'Light Wood Lattice',
    MASONRY_WALLS: 'Masonry Walls',
    SOLID_WOOD_FRAME_WALLS: 'Solid Wood Frame Walls',
    INSECT_SCREENING: 'Insect Screening',
    OTHER: 'Other'
};

export const WALL_MATERIALS = Object.entries(WALL_MATERIAL).map(material => ({
    value: material[0],
    text: material[1]
}));

export const AREA_BELOW_FLOOR_ENCLOSURE_TYPE = {
    FULL_ENCLOSED: '100% Fully enclosed',
    PARTIALLY_ENCLOSED: 'Partially enclosed'
};

export const VALUE_OF_EQUIPMENT_MAX = 'MORE_THAN_20_001';

export const VALUE_OF_EQUIPMENT = {
    LESS_THAN_10_000: '$1 — $10,000',
    BETWEEN_10_001_AND_20_000: '$10,001 — $20,000',
    [VALUE_OF_EQUIPMENT_MAX]: 'Over $20,000'
};

export const VALUE_OF_OTHER_APPLIANCES_MAX = 'MORE_THAN_10_001';

export const VALUE_OF_OTHER_APPLIANCES = {
    LESS_THAN_5_000: '$1 — $5,000',
    BETWEEN_5_001_AND_10_000: '$5,001 — $10,000',
    [VALUE_OF_OTHER_APPLIANCES_MAX]: 'Over $10,000'
};

export const PAYMENT_METHODS = {
    ACH: 'ACH',
    ALLIANCE_PAY: 'ALLIANCE_PAY',
    CREDIT_CARD: 'CREDIT_CARD',
    E_CHECK: 'E_CHECK',
    MAIL_A_CHECK: 'MAIL_A_CHECK',
    MAIL: 'MAIL'
};

export const ALLIANCE_PAYMENT_METHODS = {
    CASH: 'CASH',
    CHECK: 'CHECK',
    MONEY_ORDER: 'MONEY_ORDER'
};

export const ALLIANCE_PAYOR_OPTIONS = {
    INSURED: 'INSURED',
    MORTGAGEE: 'MORTGAGEE',
    OTHER: 'OTHER'
};

export const ALLIANCE_TRANSACTION_TYPES = {
    ENDORSEMENT: 'ENDORSEMENT',
    NEW_BUSINESS: 'NEW_BUSINESS',
    RENEWAL: 'RENEWAL',
    ROLLOVER: 'ROLLOVER'
};

export const PAYMENT_TYPES = {
    ACH: 'ACH',
    ALLIANCE_PAY: 'ALLIANCE_PAY',
    PLASTIC_CARD: 'PLASTIC_CARD',
    MAIL_A_CHECK: 'MAIL_A_CHECK',
    AGENT_ACH: 'AGENT ACH'
};

export const PAYMENT_TYPE_LABELS = {
    ACH: 'eCheck',
    ALLIANCE_PAY: 'Alliance Pay',
    PLASTIC_CARD: 'Credit Card',
    MAIL_A_CHECK: 'Mail a Check',
    AGENT_ACH: 'Agent ACH'
};

export const PAYMENT_TRANSACTION_STATUS = {
    UNKNOWN: 'UNKNOWN',
    USER_CANCELLED: 'User USER_CANCELLED',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    RECEIVED: 'RECEIVED',
    SETTLED: 'SETTLED',
    CANCELLED: 'CANCELLED',
    RETIRED: 'RETIRED'
};

export const PAYMENT_TRANSACTION_STATUS_LABELS = {
    UNKNOWN: 'Unknown',
    USER_CANCELLED: 'User Cancelled',
    SUCCESS: 'Success',
    FAILED: 'Failed',
    RECEIVED: 'Received',
    SETTLED: 'Settled',
    CANCELLED: 'Cancelled',
    RETIRED: 'Retired'
};

export const DEVICES = [
    {
        text: 'Furnace',
        value: 'FURNACE'
    },
    {
        text: 'Heat Pump',
        value: 'HEAT_PUMP'
    },
    {
        text: 'Air Conditioner',
        value: 'AIR_CONDITIONER'
    },
    {
        text: 'Hot Water Heater',
        value: 'HOT_WATER_HEATER'
    },
    {
        text: 'Oil Tank',
        value: 'FUEL_TANK'
    },
    {
        text: 'Cistern',
        value: 'CISTERN'
    },
    {
        text: 'Boiler',
        value: 'GB'
    },
    {
        text: 'Generator',
        value: 'GG'
    },
    {
        text: 'Water Filter',
        value: 'GW'
    },
    {
        text: 'Well Water Tank',
        value: 'GT'
    },
    {
        text: 'Regular Water Tank',
        value: 'GR'
    },
    {
        text: 'Fuel Tank',
        value: 'GF'
    },
    {
        text: 'Compressor',
        value: 'GC'
    }
];

export const WALL_MATERIAL_OTHER = 'OTHER';

export const ROLE_TYPES = {
    AGENT: 'AGENT',
    UNDERWRITER: 'UNDERWRITER'
};

// as of 10/04/2020
// currently: common 'A' zones do not redirect to FloodPro,
// so, we'll filter for those.
// A1-30

export const ELEVATION_CERTIFICATE_VERSIONS = {
    CEV_UNSPECIFIED: 'CEV_UNSPECIFIED',
    V_2022_11_30: 'V_2022_11_30',
    V_2018_11_30: 'V_2018_11_30',
    V_2015_07_31: 'V_2015_07_31',
    // NB: This should be V_2012_03_31, but things can't be changed now.
    V_2012_03_31: 'V_2021_03_31',
    V_2009_02_28: 'V_2009_02_28',
    V_2005_12_31: 'V_2005_12_31',
    V_OTHER: 'V_OTHER'
};

const aZones = range(30).map(num => `A${num + 1}`);
export const ELEVATION_CERTIFICATE_ZONES = [].concat(['AE']).concat(aZones);

export const BUILDING_DIAGRAM_NUMBER = {
    NUMBER_1: '1 — Slab on Grade',
    NUMBER_1A: '1a — Slab on Grade',
    NUMBER_1B: '1b — Slab on Grade',
    NUMBER_2: '2  — Basement',
    NUMBER_2A: '2a — Basement',
    NUMBER_2B: '2b — Basement',
    NUMBER_3: '3 — Split Level Slab on Grade',
    NUMBER_4: '4 — Split Level Basement',
    NUMBER_5: '5 — Elevated without Enclosure',
    NUMBER_6: '6 — Elevated With Enclosure',
    NUMBER_7: '7 — Walkout Basement',
    NUMBER_8: '8 — Above Ground Crawlspace',
    NUMBER_9: '9 — Below Ground Crawlspace'
};

export const BUILDING_DIAGRAM_NUMBER_TO_FOUNDATION_TYPE = {
    NUMBER_1: 'SLAB_ON_GRADE',
    NUMBER_1A: 'SLAB_ON_GRADE',
    NUMBER_1B: 'SLAB_ON_GRADE',
    NUMBER_2: 'BASEMENT',
    NUMBER_2A: 'BASEMENT',
    NUMBER_2B: 'BASEMENT',
    NUMBER_3: 'SPLIT_LEVEL_ON_GRADE',
    NUMBER_4: 'SPLIT_LEVEL_BASEMENT',
    NUMBER_5: 'ELEVATED_WITHOUT_ENCLOSURE',
    NUMBER_6: 'ELEVATED_WITH_ENCLOSURE',
    NUMBER_7: 'WALKOUT_BASEMENT',
    NUMBER_8: 'CRAWLSPACE',
    NUMBER_9: 'SUBGRADE_CRAWLSPACE'
};

export const EC_LABELS_COMMON = {
    addressData: 'A2',
    buildingDiagramNumber: 'A7',
    buildingWithCrawlspaceOrEnclosure: 'A8',
    squareFootage: 'a',
    numberOfOpenings: 'b',
    totalAreaOfVents: 'c',
    buildingWithAttachedGarage: 'A9',
    squareFootageGarage: 'a',
    numberOfOpeningsGarage: 'b',
    totalAreaOfVentsGarage: 'c',
    firmPanelEffectiveDate: 'B7',
    floodZone: 'B8',
    baseFloodElevation: 'B9',
    baseFloodElevationDatum: 'B11',
    buildingElevationBase: 'C1',
    buildingElevationDatum: 'C3',
    topOfBottomFloor: 'a',
    topOfNextHigherFloor: 'b',
    bottomOfTheLowestHorizontalStructure: 'c',
    attachedGarageTop: 'd',
    lowestElevationOfMachinery: 'e',
    lowestAdjacentGradeNextToBuilding: 'f',
    highestAdjacentGradeNextToBuilding: 'g',
    certificateSignatureDate: '(see section D)'
};

export const EC_LABELS_2005 = {
    buildingDiagramNumber: 'C2',
    numberOfOpeningsGarage: 'C3h',
    totalAreaOfVentsGarage: 'C3i'
};

export const EC_LABELS_2009 = {
    buildingElevationDatum: ''
};

export const EC_LABELS_2012 = {
    buildingElevationDatum: ''
};

export const EC_LABELS_2015 = {
    buildingElevationDatum: 'C2'
};

export const EC_LABELS_2018 = {
    buildingElevationDatum: 'C2'
};

export const EC_LABELS_2022 = {
    buildingElevationDatum: 'C2'
};

export const EC_LABELS_OTHER = {
    addressData: '',
    buildingDiagramNumber: '',
    buildingWithCrawlspaceOrEnclosure: '',
    squareFootage: '',
    numberOfOpenings: '',
    totalAreaOfVents: '',
    buildingWithAttachedGarage: '',
    squareFootageGarage: '',
    numberOfOpeningsGarage: '',
    totalAreaOfVentsGarage: '',
    firmPanelEffectiveDate: '',
    floodZone: '',
    baseFloodElevation: '',
    baseFloodElevationDatum: '',
    buildingElevationBase: '',
    buildingElevationDatum: '',
    topOfBottomFloor: '',
    topOfNextHigherFloor: '',
    bottomOfTheLowestHorizontalStructure: '',
    attachedGarageTop: '',
    lowestElevationOfMachinery: '',
    lowestAdjacentGradeNextToBuilding: '',
    highestAdjacentGradeNextToBuilding: '',
    certificateSignatureDate: ''
};

export const versionSelectOptions = [
    { value: 'OMB_1660_0008_11_30_2022', text: 'November 11, 2022' },
    { value: 'OMB_1660_0008_11_30_2018', text: 'November 30, 2018' },
    { value: 'OMB_1660_0008_07_31_2015', text: 'July 31, 2015' },
    { value: 'OMB_1660_0008_03_31_2012', text: 'March 31, 2012' },
    { value: 'OMB_1660_0008_02_28_2009', text: 'February 28, 2009' },
    { value: 'OMB_1660_0008_12_31_2005', text: 'December 31, 2005' },
    { value: 'OTHER', text: 'Other' }
];

export const versionLabels = {
    OMB_1660_0008_12_31_2005: { ...EC_LABELS_COMMON, ...EC_LABELS_2005 },
    OMB_1660_0008_02_28_2009: { ...EC_LABELS_COMMON, ...EC_LABELS_2009 },
    OMB_1660_0008_03_31_2012: { ...EC_LABELS_COMMON, ...EC_LABELS_2012 },
    OMB_1660_0008_07_31_2015: { ...EC_LABELS_COMMON, ...EC_LABELS_2015 },
    OMB_1660_0008_11_30_2018: { ...EC_LABELS_COMMON, ...EC_LABELS_2018 },
    OMB_1660_0008_11_30_2022: { ...EC_LABELS_COMMON, ...EC_LABELS_2022 },
    OTHER: EC_LABELS_OTHER
};

export const ecAddressMatchOptions = [
    { value: 'YES', text: 'Yes' },
    { value: 'NO', text: 'No, but property is same' },
    { value: 'CERTIFICATE_INCORRECT', text: 'No, Certificate is not correct' }
];

export const ONE_FLOOR = 'ONE_FLOOR';
export const TWO_FLOOR = 'TWO_FLOOR';
export const THREE_OR_MORE_FLOORS = 'THREE_OR_MORE_FLOORS';
export const SPLIT_LEVEL = 'SPLIT_LEVEL';
export const MANUFACTURED_HOME = 'MANUFACTURED_HOME';
export const TOWNHOUSE = 'TOWNHOUSE';

export const NUMBER_OF_FLOORS = [
    {
        text: 'One Floor',
        value: ONE_FLOOR
    },
    {
        text: 'Two Floors',
        value: TWO_FLOOR
    },
    {
        text: 'Three or More Floors',
        value: THREE_OR_MORE_FLOORS
    },
    {
        text: 'Split Level',
        value: SPLIT_LEVEL
    },
    {
        text: 'Manufactured Home',
        value: MANUFACTURED_HOME
    },
    {
        text: 'Townhouse',
        value: TOWNHOUSE
    }
];

export const BUILDING_FIRM_TYPES = {
    POST_FIRM: 'Post-FIRM',
    PRE_FIRM: 'Pre-FIRM'
};

export const FIRM_TYPES = {
    NA: 'NA',
    POST_FIRM: 'POST_FIRM',
    PRE_FIRM: 'PRE_FIRM'
};

export const INELIGIBLE_REASONS = [
    'NOT_ELIGIBLE_USE_CASE',
    'NOT_IMPLEMENTED_YET',
    'NO_RATES_AVAILABLE_FOR_THE_DATE',
    'NO_RATE_AVAILABLE',
    'NO_DEDUCTIBLE_FACTOR',
    'NOT_ELIGIBLE_FOR_NEWLY_MAPPED_EFFECTIVE_DATE_MUST_BE_WITHIN_12_MONTH_OF_FIRM_REVISION_DATE',
    'NOT_ELIGIBLE_FOR_NEWLY_MAPPED_LENDER_NOTIFICATION_OUTDATED',
    'DAY_OF_CONSTRUCTION_NOT_SPECIFIED',
    'INSURANCE_PROGRAM_NOT_SPECIFIED',
    'CONDOMINIUM_POLICY_TYPE_MUST_BE_DEFINED',
    'CONDOMINIUM_POLICY_TYPE_IS_NOT_ELIGIBLE',
    'PREV_FLOOD_ZONE_MUST_BE_DEFINED',
    'PREV_FLOOD_ZONE_MUST_IS_NOT_ELIGIBLE',
    'FLOOD_ZONE_MUST_BE_DEFINED',
    'OCCUPANCY_TYPE_MUST_BE_DEFINED',
    'CONTENTS_LOCATION_TYPE_MUST_BE_DEFINED',
    'FIRM_REVISION_DATE_MUST_BE_DEFINED',
    'MAP_PANEL_DATE_MUST_BE_DEFINED',
    'LENDER_NOTIFICATION_DATE_OF_FIRM_REVISION_MUST_BE_DEFINED',
    'NEWLY_MAPPED_TRANSACTION_TYPE_IS_NOT_ELIGIBLE',
    'NEWLY_MAPPED_TRANSACTION_TYPE_MUST_BE_DEFINED',
    'COVERAGE_TYPE_MUST_BE_DEFINED',
    'BUILDING_OR_CONTENTS_COVERAGE_MUST_BE_DEFINED',
    'BUILDING_COVERAGE_MUST_BE_OVER_THAN_ZERO',
    'CONTENTS_COVERAGE_MUST_BE_OVER_THAN_ZERO',
    'WITH_BASEMENT_OR_ENCLOSURE_MUST_BE_DEFINED',
    'BUILDING_TYPE_MUST_BE_DEFINED',
    'PROVISIONAL_FOUNDATION_TYPE_MUST_BE_DEFINED',
    'FLOOR_COUNT_MUST_BE_DEFINED',
    'CRS_PREMIUM_DISCOUNT_PERCENT_MUST_BE_DEFINED',
    'INSURANCE_PROGRAM_MUST_BE_DEFINED',
    'SEVERE_REPETITIVE_LOSS_MUST_BE_DEFINED',
    'SUBSTANTIAL_IMPROVED_MUST_BE_DEFINED',
    'ELEVATION_OF_FLOOR_BFE_MUST_BE_DEFINED',
    'DATE_OF_CONSTRUCTION_MUST_BE_DEFINED',
    'ELEVATION_CERTIFICATE_TYPE_MUST_BE_DEFINED',
    'REPLACEMENT_COST_RATIO_MUST_BE_DEFINED',
    'ELEVATED_OBSTRUCTION_MUST_BE_DEFINED',
    'ATTACHED_GARAGE_MUST_BE_DEFINED',
    'CONDOMINIUM_MUST_BE_DEFINED',
    'FOUNDATION_MUST_BE_DEFINED',
    'FOUNDATION_ELEVATING_MUST_BE_DEFINED',
    'COMMUNITY_INFO_MUST_BE_DEFINED',
    'COMMUNITY_INFO_PROGRAM_TYPE',
    'COMMUNITY_STATUS_MUST_BE_DEFINED',
    'COMMUNITY_STATUS_IS_NOT_ELIGIBLE',
    'COMMUNITY_IS_IN_CBRS',
    'COMMUNITY_IS_NOT_PRESENT_IN_NFIP',
    'CONTENTS_COVERAGE_IS_NOT_ELIGIBLE',
    'BUILDING_COVERAGE_IS_NOT_ELIGIBLE',
    'FLOOD_ZONE_TYPE_IS_NOT_ELIGIBLE',
    'DATE_OF_CONSTRUCTION_IS_NOT_ELIGIBLE',
    'OCCUPANCY_TYPE_IS_NOT_ELIGIBLE',
    'INSURANCE_PROGRAM_TYPE_IS_NOT_ELIGIBLE',
    'ELEVATION_CERTIFICATE_TYPE_IS_NOT_ELIGIBLE',
    'PROVISIONAL_FOUNDATION_TYPE_IS_NOT_ELIGIBLE',
    'COVERAGE_TYPE_IS_NOT_ELIGIBLE',
    'BUILDING_TYPE_IS_NOT_ELIGIBLE',
    'CONTENTS_LOCATION_TYPE_IS_NOT_ELIGIBLE',
    'BASEMENT_TYPE_IS_NOT_ELIGIBLE',
    'CONTENTS_LOCATION_MUST_BE_DEFINED',
    'MASTER_LOSS_MUST_BE_DEFINED',
    'MASTER_LOSS_PRP_IS_NOT_ELIGIBLE',
    'MASTER_LOSS_VIOLATION1316_IS_NOT_ELIGIBLE',
    'MASTER_LOSS_ONFEDERALLAND_IS_NOT_ELIGIBLE',
    'NOT_ELIGIBLE_FOR_CONDOMINIUM_POLICY',
    'CONDOMINIUM_BUILDING_TYPE_NOT_SPECIFIED',
    'NUMBER_OF_UNITS_NOT_SPECIFIED',
    'CONDOMINIUM_POLICY_TYPE_NOT_SPECIFIED',
    'NO_FEDERAL_POLICY_FEE_AVAILABLE',
    'NO_RESERVE_FUND_ASSESSMENT_AVAILABLE',
    'NO_PROBATION_SURCHARGE_AVAILABLE',
    'NO_HFIAA_SURCHARGE_AVAILABLE',
    'BUILDING_INSURANCE_COVERAGE_NOT_FOUND',
    'CONTENTS_INSURANCE_COVERAGE_NOT_FOUND',
    'BASIC_BUILDING_RATE_NOT_FOUND',
    'ADDITIONAL_BUILDING_RATE_NOT_FOUND',
    'BASIC_CONTENTS_RATE_NOT_FOUND',
    'ADDITIONAL_CONTENTS_RATE_NOT_FOUND',
    'PROBATION_SURCHARGE_NOT_FOUND',
    'ICC_PREMIUM_NOT_FOUND',
    'FEDERAL_POLICY_FEE_NOT_FOUND',
    'HFIAA_SURCHARGE_NOT_FOUND',
    'RESERVE_FUND_ASSESSMENT_PERCENT_NOT_FOUND',
    'CRS_PREMIUM_DISCOUNT_PERCENT_NOT_FOUND',
    'BASE_PREMIUM_NOT_FOUND',
    'MULTIPLIER_NOT_FOUND',
    'SEVERE_REPETITIVE_LOSS_IS_PRESENT_IN_FEMA',
    'ABOVE_GROUND_LEVEL_FOR_GIVEN_OCCUPANCY_TYPE_INELIGIBLE_FOR_PROVISIONAL',
    'FOUNDATION_CONTENTS_BUILDING_COMBINATION_INELIGIBLE_FOR_PROVISIONAL',
    'INTERNAL_SERVER_ERROR',
    'SUBMIT_FOR_RATE',
    'WRONG_CONTENT_FOR_MANUFACTURED_HOME'
];

export const CONTENT_LOCATIONS_LABELS = {
    BASEMENT_ONLY: 'Basement',
    ABOVE_GROUND_LEVEL_MORE_THAN_ONE_FULL_FLOOR:
        'Above Ground Level More Than 1 Floor',
    BASEMENT_ENCLOSURE_AND_ABOVE:
        'Basement, Enclosure, or Crawlspace and Above',
    LOWEST_FLOOR_ABOVE_GROUND_LEVEL_AND_HIGHER_FLOORS:
        'Lowest Floor Above Ground Level and Higher',
    LOWEST_FLOOR_ONLY_ABOVE_GROUND_LEVEL:
        'Lowest Floor Only Above Ground Level',
    MANUFACTURED_HOME: 'In the Mobile Home'
};

export const VALIDATION_LEVELS = {
    ERROR: 'LEVEL_ERROR'
};

export const VALIDATION_FORMAT_TYPES = {
    PHONE: 'FORMAT_TYPE_PHONE_NUMBER',
    EMAIL: 'FORMAT_TYPE_EMAIL'
};

export const SERVICE_LINK_REQUEST_LEVEL = {
    SERVICE_LINK_REQUEST_LEVEL_UNSPECIFIED:
        'SERVICE_LINK_REQUEST_LEVEL_UNSPECIFIED',
    SERVICE_LINK_REQUEST_LEVEL_GUARANTEED:
        'SERVICE_LINK_REQUEST_LEVEL_GUARANTEED',
    SERVICE_LINK_REQUEST_LEVEL_NON_GUARANTEED:
        'SERVICE_LINK_REQUEST_LEVEL_NON_GUARANTEED'
};

export const COMMUNITY_STATUS_INELIGIBLE_REVIEW_REASONS = {
    COMMUNITY_STATUS_NON_PARTICIPATING: 'COMMUNITY_STATUS_NON_PARTICIPATING',
    COMMUNITY_STATUS_SUSPENDED: 'COMMUNITY_STATUS_SUSPENDED'
};

export const INELIGIBLE_REVIEW_REASONS = {
    NFIP_NOT_PARTICIPATING: 'NFIP_NOT_PARTICIPATING',
    NFIP_SUSPENDED: 'NFIP_SUSPENDED',
    SEVERE_REPETITIVE_LOSS: 'SEVERE_REPETITIVE_LOSS',
    VIOLATION_1316: 'VIOLATION_1316',
    FLOODZONE_UNDETERMINED: 'FLOODZONE_UNDETERMINED',
    AWAITING_FZD: 'AWAITING_FZD'
};

export const APPLICATION_SUCCESS_STATUS = {
    STATE_AWAITING_FZD: 'STATE_AWAITING_FZD'
};

export const APPLICATION_STATUSES = {
    STATE_UNSPECIFIED: 'STATE_UNSPECIFIED',
    STATE_AWAITING_FZD: 'STATE_AWAITING_FZD',
    STATE_NEW: 'STATE_NEW',
    STATE_LOCATED: 'STATE_LOCATED',
    STATE_ZONED: 'STATE_ZONED',
    STATE_RATED: 'STATE_RATED',
    STATE_SIGNED: 'STATE_SIGNED',
    STATE_PAID: 'STATE_PAID',
    STATE_REJECTED: 'STATE_REJECTED',
    STATE_UNDERWRITTEN: 'STATE_UNDERWRITTEN'
};

export const COVERAGE_TYPE = {
    COVERAGE_TYPE_BUILDING_AND_CONTENTS: 'COVERAGE_TYPE_BUILDING_AND_CONTENTS',
    COVERAGE_TYPE_BUILDING: 'COVERAGE_TYPE_BUILDING',
    COVERAGE_TYPE_CONTENTS: 'COVERAGE_TYPE_CONTENTS'
};

export const COVERAGE_TYPE_OPTIONS = [
    {
        text: 'Building and Contents',
        value: COVERAGE_TYPE.COVERAGE_TYPE_BUILDING_AND_CONTENTS
    },
    {
        text: 'Building only',
        value: COVERAGE_TYPE.COVERAGE_TYPE_BUILDING
    },
    {
        text: 'Contents only',
        value: COVERAGE_TYPE.COVERAGE_TYPE_CONTENTS
    }
];

export const BUILDING_DEDUCTIBLE_OPTIONS = [
    {
        text: '$0',
        value: '0'
    },
    {
        text: '$1,000',
        value: '1000'
    },
    {
        text: '$1,250',
        value: '1250'
    },
    {
        text: '$1,500',
        value: '1500'
    },
    {
        text: '$2,000',
        value: '2000'
    },
    {
        text: '$5,000',
        value: '5000'
    },
    {
        text: '$10,000',
        value: '10000'
    },
    {
        text: '$25,000',
        value: '25000'
    },
    {
        text: '$50,000',
        value: '50000'
    }
];

export const CONTENTS_DEDUCTIBLE_OPTIONS = [
    {
        text: '$0',
        value: '0'
    },
    {
        text: '$1,000',
        value: '1000'
    },
    {
        text: '$1,250',
        value: '1250'
    },
    {
        text: '$1,500',
        value: '1500'
    },
    {
        text: '$2,000',
        value: '2000'
    },
    {
        text: '$5,000',
        value: '5000'
    },
    {
        text: '$10,000',
        value: '10000'
    },
    {
        text: '$25,000',
        value: '25000'
    },
    {
        text: '$50,000',
        value: '50000'
    }
];

export const STATUS_TYPES = {
    STATUS_UNSPECIFIED: 'STATUS_UNSPECIFIED',
    STATUS_STARTED: 'STATUS_STARTED',
    STATUS_SUCCESS: 'STATUS_SUCCESS',
    STATUS_FAILED: 'STATUS_FAILED'
};

export const CONTINUING_EDUCATION_COPY = {
    ALLSTATE_COPY:
        'I declare that I have met the minimum training and education requirements related to flood insurance, as required by Section 207 of the Flood Insurance Reform Act of 2004 and the applicable state property insurance licensing requirements. I further attest that I will maintain the certificate(s) for the aforementioned course(s) and will provide them to Allstate upon request.',
    FARMERS_COPY:
        'I attest that I have complied with the minimum flood training requirements set by FEMA and/or the states in which I’m licensed to write flood, through an education provider approved by the state(s) in which I’m licensed to write this insurance. I also attest that I can provide the required information and that I am the individual producer writing this flood policy.'
};

export const PAY_RENEWAL = 'pay-renewal';
