import MUILink from '@material-ui/core/Link';
import getConfig from 'next/config';
import NextLink from 'next/link';
import PropTypes from 'prop-types';

import getRedirects from '../feature-flags';
import getPPUIHost from '../lib/get-ppui-host';

const { publicRuntimeConfig } = getConfig() ?? {};
const { NEXT_PUBLIC_FEATURE_FLAGS } = publicRuntimeConfig ?? {};

const Link = props => {
    const {
        children,
        component,
        href,
        passHref,
        prefetch,
        target,
        variant,
        underline,
        classes,
        color
    } = props;

    const getLinkHref = () => {
        // Phase 1 - Linking (AX-1114)
        // Phase 2 - Linking
        // Phase 3 - Linking (AX-1535)
        const redirects = getRedirects(NEXT_PUBLIC_FEATURE_FLAGS);
        const shouldRedirect = redirects.some(({ url }) => href?.includes(url));

        if (NEXT_PUBLIC_FEATURE_FLAGS && shouldRedirect) {
            return `${getPPUIHost()}${href}`;
        }

        if (!href) {
            return '#';
        }

        return href;
    };

    let linkProps = {
        underline,
        classes,
        color,
        href: getLinkHref()
    };

    if (component) {
        linkProps = { ...linkProps, component };
    }

    if (variant) {
        linkProps = { ...linkProps, variant };
    }

    if (target) {
        /**
         * When you use target="_blank" with Links, it is recommended to always set rel="noopener" or rel="noreferrer" when linking to third party content.
         * @see https://material-ui.com/components/links/#security
         */
        linkProps = { ...linkProps, rel: 'noopener', target };
    }

    return (
        <NextLink
            href={getLinkHref() || '#'}
            passHref={passHref}
            prefetch={prefetch}
        >
            <MUILink {...linkProps}>{children}</MUILink>
        </NextLink>
    );
};

Link.propTypes = {
    children: PropTypes.node.isRequired,
    component: PropTypes.string,
    href: PropTypes.string.isRequired,
    passHref: PropTypes.bool,
    prefetch: PropTypes.bool,
    target: PropTypes.string,
    variant: PropTypes.string,
    underline: PropTypes.string,
    classes: PropTypes.object,
    color: PropTypes.string
};

Link.defaultProps = {
    component: '',
    passHref: false,
    prefetch: false,
    target: null,
    variant: null,
    underline: 'none',
    classes: {},
    color: 'primary'
};

export default Link;
