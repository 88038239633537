import PropTypes from 'prop-types';
import Box from '@nfs/ui-kit/Box';
import Button from '@nfs/ui-kit/Button';
import Dialog from '@nfs/ui-kit/Dialog';
import Grid from '@nfs/ui-kit/Grid';
import Typography from '@nfs/ui-kit/Typography';

/**
 * Continuing Education Attestation dialog
 *
 * @param {*} props
 * @returns
 */
const ContinuingEducationAttestationDialog = props => {
    const { copy, loading, onClose, onSubmit, open, title } = props;

    return (
        <Dialog
            scroll="body"
            maxWidth="sm"
            title={title}
            open={open}
            actions={
                <>
                    <Button
                        color="secondary"
                        onClick={onClose}
                        variant="outlined"
                    >
                        No
                    </Button>
                    <Button
                        color="primary"
                        disabled={loading}
                        loading={loading}
                        onClick={onSubmit}
                    >
                        Yes
                    </Button>
                </>
            }
        >
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <Box>
                        <Typography component="p">{copy}</Typography>
                    </Box>
                </Grid>
            </Grid>
        </Dialog>
    );
};

ContinuingEducationAttestationDialog.propTypes = {
    copy: PropTypes.string,
    loading: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string
};

ContinuingEducationAttestationDialog.defaultProps = {
    copy: '',
    loading: false,
    onClose: () => {},
    onSubmit: () => {},
    open: false,
    title: 'Continuing Education Attestation'
};

export default ContinuingEducationAttestationDialog;
